<template>
  <div class="index_wrap" >
    <div class="header_wrap" >
      <div class="title" >
        <img src="./img/1.png" class="leaf_icon" />
        {{ data.report.physique_name }}
      </div>
      <div class="sub_title" > {{ data.report.physique_name }}</div>
      <div class="user_wrap" >
        <img src="./img/2.png" class="user_img" />
        <div class="user_content" >
          <div id="chart" ></div>
          <div class="title" >您的体检得分</div>
          <div class="desc" >
            <div>本报告分析结果，仅供参考</div>
            <div>不可作为医疗诊断结论，</div>
            <div>如已有身体不适，请及时咨询医生。</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_list" >
      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 0 }"
           @click="changeCate(0)"
      >
        <div class="circle" ></div>
        体质分析
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 1 }"
           @click="changeCate(1)"
      >
        <div class="circle" ></div>
        调理方案
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 2 }"
           @click="changeCate(2)"
      >
        <div class="circle" ></div>
        饮食建议
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 3 }"
           @click="changeCate(3)"
      >
        <div class="circle" ></div>
        音乐建议
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 4 }"
           @click="changeCate(4)"
      >
        <div class="circle" ></div>
        起居建议
      </div>

      <div class="tabs_item"
           :class="{ 'tabs_active': cate == 5 }"
           @click="changeCate(5)"
      >
        <div class="circle" ></div>
        运动建议
      </div>
    </div>

    <div class="content" v-if="cate == 0">
      <div class="tag_list"  >
        <div class="tag_item"
             :class="{ 'tags_active': tag == 0 }"
             @click="changeTag(0)"
        >体质分析</div>
        <div class="tag_item"
             :class="{ 'tags_active': tag == 1 }"
             @click="changeTag(1)"
        >风险预警</div>
        <div class="tag_item"
             :class="{ 'tags_active': tag == 2 }"
             @click="changeTag(2)"
        >识别特征</div>
        <div class="tag_item"
             :class="{ 'tags_active': tag == 3 }"
             @click="changeTag(3)"
        >证型信息</div>
      </div>
      <template>
        <template v-if="tag == 0" >
          <div class="analysis_content" >
<!--            <div class="analysis_title">体质分析</div>-->
            <div class="analysis_text" style="margin-top: 0" >
              {{ data.report.physique_analysis }}
            </div>
          </div>
        </template>

        <template v-if="tag == 1" >
          <div class="analysis_content" >
            <div class="analysis_title">风险预警</div>
            <div class="analysis_text" >
              {{ data.report.risk_warning }}
            </div>
          </div>
        </template>
        <template v-if="tag == 2" >
          <div class="title" >{{ data.report.syndrome_name }}</div>
          <div class="desc" >
            {{ data.report.typical_symptom }}
          </div>
          <div class="type_list" >
            <div class="type_item"
                 :class="{ 'type_active': type == 0 }"
                 @click="changeType(0)"
            >
              面部
            </div>
            <div class="type_item"
                 :class="{ 'type_active': type == 1 }"
                 @click="changeType(1)"
            >
              舌部
            </div>
          </div>

          <div class="result_content" v-if="type == 0" >
            <div class="title" >
              <div>面部</div>
              <div>FACE</div>
            </div>
            <div class="result_content_right">
              <div class="result_content_item" v-for="(item, index) in data.report.features" :key="index" v-if="item.feature_category == '面部'" >
                <div class="result_header" >
                  <div class="header_left" >
                    <div>{{ item.feature_group }}</div>
                    <div>{{ item.feature_name }}</div>
                  </div>
                  <div class="error_tag" v-if="item.feature_situation == '异常'" >{{ item.feature_situation }}</div>
                  <div class="result_tag" v-else >{{ item.feature_situation }}</div>
                </div>
                <div class="result_desc" >
                  {{ item.feature_interpret }}
                </div>
                <div class="line" ></div>
              </div>
            </div>
          </div>

          <div class="result_content" v-if="type == 1" >
            <div class="title" >
              <div>舌部</div>
              <div>TONGUE</div>
            </div>
            <div class="result_content_right">
              <div class="result_content_item" v-for="(item, index) in data.report.features" :key="index" v-if="item.feature_category == '舌部'" >
                <div class="result_header" >
                  <div class="header_left" >
                    <div>{{ item.feature_group }}</div>
                    <div>{{ item.feature_name }}</div>
                  </div>
                  <div class="error_tag" v-if="item.feature_situation == '异常'" >{{ item.feature_situation }}</div>
                  <div class="result_tag" v-else >{{ item.feature_situation }}</div>
                </div>
                <div class="result_desc" >
                  {{ item.feature_interpret }}
                </div>
                <div class="line" ></div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="tag == 3" >
          <div class="analysis_content" >
            <div class="analysis_title" >{{ data.report.syndrome_name }}</div>
            <div class="analysis_text">
              {{ data.report.syndrome_introduction }}
            </div>
          </div>
        </template>
      </template>
    </div>
    <div class="content cate_content" v-if="cate == 1" >
      <div class="goods_list" >
        <div class="goods_item" :class="{ 'goods_active': goodsIndex == index }"
             v-for="(item, index) in data.goodsVoList"
             @click="changeGoods(item.goodsId, index)"
        >
          <div class="goods_block" >
            <img :src="item.pic" class="goods_img" />
          </div>
          <div class="goods_text" >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="recommend_content" v-if="goods" >
        <div class="recommend_title" >{{ goods.name }}</div>
        <div class="recommend_desc" >
          {{ goods.introduction }}
        </div>
        <div class="yiju_title" v-if="goods.goodsBasisVoList" >· 推荐依据</div>
        <div v-for="(item, index) in goods.goodsBasisVoList" :key="index" >
          <div class="recommend_tag_list"  >
            <div class="recommend_tag_item" v-for="(child, c) in item.goodsBasisQuestionVoList" :key="c" >
              {{ child.question }}
            </div>
          </div>
          <div class="recommend_desc_content" >
           {{ item.detail }}
          </div>
        </div>

        <template  v-if="goods.goodsEntryVoList" v-for="item in goods.goodsEntryVoList" >
          <div class="yiju_title" style="margin: 5px 0 0 0" >· {{ item.name }}</div>
          <div class="recommend_desc_content" >
            {{ item.detail }}
          </div>
        </template>
      </div>
    </div>

    <div class="content" v-if="cate == 2" >
      <div class="analysis_content" >
        <div v-for="item in data.report.advices.food" >
          <div class="analysis_title">{{ item.title }}</div>
          <div class="analysis_text" >
            {{ item.advice }}
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="cate == 3" >
      <div class="analysis_content" >
        <div v-for="item in data.report.advices.music" >
          <div class="analysis_title">{{ item.title }}</div>
          <div class="analysis_text" >
            {{ item.advice }}
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="cate == 4" >
      <div class="analysis_content" >
        <div v-for="item in data.report.advices.sleep" >
          <div class="analysis_title">{{ item.title }}</div>
          <div class="analysis_text" >
            {{ item.advice }}
          </div>
        </div>
      </div>
    </div>

    <div class="content" v-if="cate == 5" >
      <div class="analysis_content" >
        <div v-for="item in data.report.advices.sport" >
          <div class="analysis_title">{{ item.title }}</div>
          <div class="analysis_text" >
            {{ item.advice }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getTongueCaseVo,
  getCaseGoodsVo
} from '@/api/report'
export default {
  name: 'report',
  data() {
    return {
      tag: 0,
      type: 0,
      tongueCaseId: this.$route.params.tongueCaseId,
      data: {
        report: {
          physique_name: '',
          physique_analysis: '',
          typical_symptom: '',
          syndrome_name: '',
          features: [],
          syndrome_introduction: '',
          advices: {
            food: [],
            music: [],
            sleep: [],
            sport: []
          },
          score: 0
        },
        goodsVoList: [],
      },
      goods: null,
      cate: 0,
      goodsIndex: 0
    }
  },
  methods: {
    changeGoods(goodsId, goodsIndex){
      this.goodsIndex = goodsIndex
      this.getGoodsDetail(goodsId)
    },
    getGoodsDetail(goodsId) {
      const { tongueCaseId } = this
      getCaseGoodsVo({ goodsId, tongueCaseId }).then(res => {
        if(res.success){
          this.goods = res.data
          console.log('goods:', this.goods)
        }
      })
    },
    changeCate(cate) {
      this.cate = cate
      switch (cate) {
        case 1:
          if(this.data.goodsVoList.length > 0) {
            this.goodsIndex = 0
            this.getGoodsDetail(this.data.goodsVoList[this.goodsIndex].goodsId)
          }
          break
      }
    },
    changeType(type) {
      this.type = type
    },
    changeTag(tag) {
      this.tag = tag
    },
    initChart() {
      let chart = this.$echarts.init(document.getElementById('chart'))
      var option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['72%', '45%'],
            radius: '80%',
            min: 0,
            max: 1,
            splitNumber: 5,
            axisLine: {
              lineStyle: {
                width: 3,
                color: [
                  [0.25, '#FC6269'],
                  [0.5, '#FFE08E'],
                  [0.75, 'rgba(3, 207, 175, .8)'],
                  [1, '#03CFAF']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 10,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              distance: 3,
              length: 3,
              lineStyle: {
                color: 'auto',
                width: 1
              }
            },
            splitLine: {
              length: 4,
              distance: 5,
              lineStyle: {
                color: 'auto',
                width: 2
              }
            },
            axisLabel: {
              show: false,
            },
            detail: {
              fontSize: 20,
              offsetCenter: [0, '-20%'],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value * 100) + '分';
              },
              color: 'inherit'
            },
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: 'orange' // 设置系列标签文字颜色为橙色
                }
              }
            },
            data: [
              {
                value: this.data.report.score/100,
              }
            ]
          }
        ]
      };

      chart.setOption(option)
    },
    getData() {
      const { tongueCaseId } = this
      getTongueCaseVo({ tongueCaseId }).then(res => {
        if(res.success) {
          this.data = res.data
          console.log({...this.data})
          this.initChart()
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
